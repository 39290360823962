@import url("https://fonts.googleapis.com/css2?family=Sora:wght@200;300;400;500;600&display=swap");
@import url("https://api.fontshare.com/v2/css?f[]=satoshi@900,700,500,400,450,300,200&display=swap");
/* @import url("https://api.fontshare.com/v2/css?f[]=satoshi@1&display=swap"); */
@import "react-quill/dist/quill.snow.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .main-container {
    @apply mx-auto max-w-[1560px] px-4 md:px-8 lg:px-2;
  }
}

html {
  overflow-y: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "sora", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #060f1b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.p-color {
  color: rgba(12, 115, 113, 0.51);
}

.lorem-color {
  color: rgba(34, 34, 34, 0.55);
}

.card-border {
  box-sizing: border-box;
  background: #ffffff;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("/src/assets/Icons/Calendar.svg") center/90% no-repeat;
  color: rgba(0, 0, 0, 0);
  opacity: 0.5;
}

.secondary-scrollbar::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

.secondary-scrollbar::-webkit-scrollbar-track {
  background: #060f1b;
}

.secondary-scrollbar::-webkit-scrollbar-thumb {
  background-color: #b58d8c;
  border-radius: 2px;
}

.spin-button-none::-webkit-inner-spin-button,
.spin-button-none::-webkit-outer-spin-button {
  appearance: none;
}
.spin-button-none {
  appearance: textfield;
}

.magnet-card-background {
  background: linear-gradient(
    225.96deg,
    #fbfbfd -10.51%,
    #c2d0d8 5.9%,
    #ffffff 16.43%,
    #9db0be 28.83%,
    #e3e9ee 42.56%,
    #fafbfc 59.92%,
    #8695a0 86.87%,
    #b8c9d3 97.48%
  );
}
.magnet-price-card-background {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    conic-gradient(
      from 180deg at 48.5% 50%,
      #b8c9d3 -28.12deg,
      #fbfbfd 26.25deg,
      #c8d4da 88.12deg,
      #ffffff 156.58deg,
      #aec0ce 191.74deg,
      #e3e9ee 237.13deg,
      #fafbfc 255.19deg,
      #d6dfe6 310.11deg,
      #b8c9d3 331.88deg,
      #fbfbfd 386.25deg
    );
}

.incentive-card-background {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    conic-gradient(
      from 180deg at 48.5% 50%,
      #98c9e6 -28.12deg,
      #cefff7 26.25deg,
      #a9c9da 88.12deg,
      #ffffff 156.58deg,
      #9fd0f5 191.74deg,
      #a0bbd1 237.13deg,
      #cae4ff 255.19deg,
      #86b9e0 310.11deg,
      #98c9e6 331.88deg,
      #cefff7 386.25deg
    );
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    conic-gradient(
      from 180deg at 48.5% 50%,
      #98c9e6 -28.12deg,
      #cefff7 26.25deg,
      #a9c9da 88.12deg,
      #ffffff 156.58deg,
      #9fd0f5 191.74deg,
      #a0bbd1 237.13deg,
      #cae4ff 255.19deg,
      #86b9e0 310.11deg,
      #98c9e6 331.88deg,
      #cefff7 386.25deg
    ); */
}

body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

body::-webkit-scrollbar-track {
  background: #060f1b;
}

body::-webkit-scrollbar-thumb {
  background-color: #b58d8c;
  border-radius: 20px;
}

.three-dots-loading {
  font-weight: bold;
  display: inline-block;
  font-family: monospace;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}

.grecaptcha-badge {
  visibility: hidden !important;
}

.textarea-resizer {
  overflow: hidden;
}

.textarea-resizer::-webkit-resizer {
  display: none;
}

/* Make images responsive within the Quill editor */
.ql-editor img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
